<template>
  <div v-if="user && roleOptions">
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="user.avatar ? user.avatar : ''"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          上传头像
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          name="uploadFile"
          :hidden="true"
          plain
          @input="uploadFile"
        />
        <!--/ upload button -->

        <b-card-text>只接受 JPG, GIF 和 PNG. 最大尺寸 800KB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <validation-Observer>
        <b-row>
          <b-col sm="5">
            <validation-provider
              #default="{ errors }"
              name="*email*"
              rules="required|email"
            >
              <b-form-group label-for="account-e-mail">
                <label class="star">E-mail</label>
                <b-form-input
                  v-model="user.email"
                  name="email"
                  placeholder="Email"
                  :disabled="user.id ? true : false"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5">
            <b-form-group label-for="account-nickName">
              <label class="star">昵称</label>
              <b-form-input
                v-model="user.nickName"
                placeholder="nickName"
                name="nickName"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5">
            <validation-provider
              #default="{ errors }"
              name="*手机*"
              rules="required|mobile"
            >
              <b-form-group label-for="account-mobile">
                <label class="star">手机</label>
                <b-form-input
                  v-model="user.mobile"
                  name="mobile"
                  placeholder="手机"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5" v-if="user.id == 0">
            <validation-provider
              #default="{ errors }"
              name="*密码*"
              rules="required|password"
            >
              <b-form-group label-for="account-password">
                <label class="star">密码</label>
                <b-form-input
                  v-model="user.password"
                  name="password"
                  placeholder="密码"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5">
            <validation-provider
              #default="{ errors }"
              name="*角色*"
              rules="required|integer"
            >
              <b-form-group label-for="account-roleId">
                <label class="star">角色</label>
                <b-form-select
                  v-model="user.roleId"
                  :options="roleOptions"
                  :disabled="user.default == 1"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5">
            <validation-provider
              #default="{ errors }"
              name="*部门*"
              rules="required|integer"
            >
              <b-form-group label-for="account-roleId">
                <label class="star">部门</label>
                <b-form-select
                  v-model="user.orgId"
                  :options="orgOptions"
                  :disabled="user.default == 1"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="5" v-if="user.id != 0">
            <b-form-group label-for="account-">
              <label class="star">启用/停用</label>
              <b-form-checkbox name="check-button" v-model="user.status" switch inline>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5" class="text-right">
            <b-button variant="primary" class="mt-2 mr-1" @click="save">
              保存修改
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="backToList"
            >
              取消返回
            </b-button>
          </b-col>
        </b-row>
      </validation-Observer>
    </b-form>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="确定"
      cancel-title="Close"
      centered
      title="选择角色"
    >
      <b-form>
        <b-form-group>
          <label for="email">角色</label>
          <b-form-input v-model="user.roleId" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>


<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { isNumber } from "@vueuse/shared";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, password, number, email, mobile } from "@validations";

export default {
  name: "UserEdit",

  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormCheckbox,
  },
  data() {
    return {
      user: {
        id: 0,
        eid: 1,
        nickName: "",
        email: "",
        mobile: "",
        roleId: 0,
        orgId: 0,
        avatar:
          "https://services.zhenhecai.com/it/files/avatar/defaultavatar0.png",
      },
      profileFile: null,

      orgOptions: [],

      roleOptions: [],
    };
  },

  beforeCreate() {
    let id = parseInt(this.$route.params.id);
    // console.log("user id=" + id);

    if (isNumber(id)) {
      this.user = this.$http.get("/users/" + id).then((res) => {
        this.user = res.data.data;
      });

      this.$http.get("/roles?eid=1&p=1&ps=100").then((res) => {
        this.roleOptions = res.data.data.list.map((role) => {
          return {
            value: role.id,
            text: role.name,
          };
        });
      });

      this.$http.get("/organizations").then((res) => {
        this.orgOptions = res.data.data.list.map((org) => {
          return {
            value: org.id,
            text: org.name,
          };
        });
      });
    }
  },

  computed: {
    // role select options
  },

  methods: {
    // get role name by roleId from roles
    roleName(id) {
      // find the role which id=id
      let role = this.roles.find((item) => item.id == id);

      // return the role.name if the role is found
      return role ? role.name : "";
    },

    save() {
      if (this.user.id) {
        // update
        // this.user.roleId = parseInt(this.user.roleId);
        let user = {
          id: this.user.id,
          nickName: this.user.nickName,
          mobile: this.user.mobile,
          roleId: this.user.roleId,
        };
        this.$http
          .put("/users/" + this.user.id, user)
          .then((res) => {
            this.successed("保存成功");
          })
          .catch((err) => {
            this.$swal({
              title: "保存失败",
              text: "",
              icon: "error",
            });
          });
      } else {
        // create
        this.$http.post("/users", this.user).then((res) => {
          this.$swal({
            title: "保存成功",
            text: "成功",
            icon: "success",
          });
        });
      }
    },

    successed(msg) {
      this.$swal({
        title: "成功",
        text: msg,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "确认",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // this.backToList();
        }
      });
    },

    backToList() {
      this.$router.push({ name: "user" });
    },

    resetForm() {
      this.user = JSON.parse(JSON.stringify(this.user));
    },

    uploadFile() {
      if (this.profileFile) {
        let formData = new FormData();
        formData.append("uploadFile", this.profileFile);
        this.$http
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.user.avatar = res.data.data.url;
          })
          .catch((err) => {
            // debugger;
          });
      }
    },
  },

  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      // inputImageRenderer,
    };
  },
};
</script>
<style scoped>
label.star::before {
  content: "* ";
  color: red;
}
</style>
